import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgTranslate2(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path d="M18.5 10l4.4 11h-2.155l-1.201-3h-4.09l-1.199 3h-2.154L16.5 10h2zM10 2v2h6v2h-1.968a18.222 18.222 0 01-3.62 6.301 14.864 14.864 0 002.336 1.707l-.751 1.878A17.015 17.015 0 019 13.725a16.676 16.676 0 01-6.201 3.548l-.536-1.929a14.7 14.7 0 005.327-3.042A18.078 18.078 0 014.767 8h2.24A16.032 16.032 0 009 10.877a16.165 16.165 0 002.91-4.876L2 6V4h6V2h2zm7.5 10.885L16.253 16h2.492L17.5 12.885z" />
    </Svg>
  );
}

export default SvgTranslate2;
