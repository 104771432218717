import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgContrast2Line(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 100-16 8 8 0 000 16zm-5-4.68a8.965 8.965 0 005.707-2.613A8.965 8.965 0 0015.32 7 6 6 0 117 15.32z" />
    </Svg>
  );
}

export default SvgContrast2Line;
