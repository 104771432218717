import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgAliensLine(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path d="M12 2a8.5 8.5 0 018.5 8.5c0 6.5-5.5 12-8.5 12s-8.5-5.5-8.5-12A8.5 8.5 0 0112 2zm0 2a6.5 6.5 0 00-6.5 6.5c0 4.794 4.165 10 6.5 10s6.5-5.206 6.5-10A6.5 6.5 0 0012 4zm5.5 7c.16 0 .319.008.475.025a4.5 4.5 0 01-4.95 4.95A4.5 4.5 0 0117.5 11zm-11 0a4.5 4.5 0 014.475 4.975 4.5 4.5 0 01-4.95-4.95C6.18 11.008 6.34 11 6.5 11z" />
    </Svg>
  );
}

export default SvgAliensLine;
