import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgSimCard2Fill(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path d="M5 2h10l4.707 4.707a1 1 0 01.293.707V21a1 1 0 01-1 1H5a1 1 0 01-1-1V3a1 1 0 011-1zm8 16v-8H8v2h3v6h2zm-5-5v2h2v-2H8zm6 0v2h2v-2h-2zm0-3v2h2v-2h-2zm-6 6v2h2v-2H8zm6 0v2h2v-2h-2z" />
    </Svg>
  );
}

export default SvgSimCard2Fill;
