import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgEvernoteFill(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path d="M8.63 7.754c-.216.201-.546.217-.743.217h-2.11c-.61 0-.974 0-1.22.033-.134.017-.298.084-.381.117-.033.016-.033 0-.017-.016l4.816-4.94c.017-.017.033-.017.017.017a1.734 1.734 0 00-.116.382c-.033.249-.033.615-.033 1.23v2.212c0 .2-.017.533-.214.748zm4.682 14.184c-.56-.366-.857-.848-.973-1.147a2.443 2.443 0 01-.181-.915 2.513 2.513 0 012.507-2.51c.412 0 .742.332.742.748a.735.735 0 01-.38.648.946.946 0 01-.28.1c-.082.017-.396.05-.543.183a.776.776 0 00-.298.582.92.92 0 00.264.649c.297.299.693.465 1.122.465a2.036 2.036 0 002.028-2.045c0-1.014-.676-1.913-1.567-2.311-.132-.067-.346-.117-.544-.167a6.719 6.719 0 00-.495-.083c-.693-.084-2.424-.632-2.54-2.178 0 0-.51 2.328-1.534 2.96-.098.05-.23.1-.379.133-.148.033-.312.05-.363.05-1.665.1-3.43-.433-4.65-1.696 0 0-.825-.682-1.253-2.594-.099-.466-.297-1.298-.412-2.08-.05-.281-.067-.498-.083-.698 0-.814.495-1.363 1.121-1.445h3.365c.576 0 .907-.15 1.121-.35.28-.266.347-.649.347-1.098V3.631c.08-.615.627-1.131 1.434-1.131h.396c.165 0 .363.017.544.033.132.017.247.05.445.1 1.006.25 1.22 1.28 1.22 1.28l2.854.5c.907.166 3.15.316 3.578 2.594 1.006 5.42.396 10.675.347 10.675-.71 5.121-4.931 4.871-4.931 4.871a3.426 3.426 0 01-2.029-.615zm2.622-10.309c-.033.084-.066.183-.05.233.018.05.051.066.084.083.198.1.527.15 1.006.2.478.05.808.083 1.022.05.033 0 .067-.017.1-.067s.016-.15.016-.233c-.05-.449-.462-.781-1.006-.848-.545-.05-1.006.167-1.172.582z" />
    </Svg>
  );
}

export default SvgEvernoteFill;
