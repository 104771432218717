import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgBriefcase5Line(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path d="M7 5V2a1 1 0 011-1h8a1 1 0 011 1v3h4a1 1 0 011 1v14a1 1 0 01-1 1H3a1 1 0 01-1-1V6a1 1 0 011-1h4zm9 10h-3v1h-2v-1H8v4h8v-4zM8 7v6h3v-1h2v1h3V7H8zm-2 6V7H4v6h2zm12 0h2V7h-2v6zM6 15H4v4h2v-4zm12 0v4h2v-4h-2zM9 3v2h6V3H9z" />
    </Svg>
  );
}

export default SvgBriefcase5Line;
