import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgCreativeCommonsNdLine(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm0 2a8 8 0 100 16 8 8 0 000-16zm4 9v2H8v-2h8zm0-4v2H8V9h8z" />
    </Svg>
  );
}

export default SvgCreativeCommonsNdLine;
