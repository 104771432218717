import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgGameFill(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path d="M12 2a9.98 9.98 0 017.743 3.671L13.414 12l6.329 6.329A9.98 9.98 0 0112 22C6.477 22 2 17.523 2 12S6.477 2 12 2zm0 3a1.5 1.5 0 100 3 1.5 1.5 0 000-3z" />
    </Svg>
  );
}

export default SvgGameFill;
