import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgHome8Fill(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path d="M20 20a1 1 0 01-1 1H5a1 1 0 01-1-1v-9H1l10.327-9.388a1 1 0 011.346 0L23 11h-3v9zM9 10v6h6v-6H9zm2 2h2v2h-2v-2z" />
    </Svg>
  );
}

export default SvgHome8Fill;
