import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgMessengerLine(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path d="M7.764 19.225c.59-.26 1.25-.309 1.868-.139.77.21 1.565.316 2.368.314 4.585 0 8-3.287 8-7.7S16.585 4 12 4s-8 3.287-8 7.7c0 2.27.896 4.272 2.466 5.676a2.8 2.8 0 01.942 2.006l.356-.157zM12 2c5.634 0 10 4.127 10 9.7 0 5.573-4.366 9.7-10 9.7a10.894 10.894 0 01-2.895-.384.8.8 0 00-.534.039l-1.984.876a.8.8 0 01-1.123-.707l-.055-1.78a.797.797 0 00-.268-.57C3.195 17.135 2 14.617 2 11.7 2 6.127 6.367 2 12 2zM5.995 14.537l2.937-4.66a1.5 1.5 0 012.17-.4l2.336 1.75a.6.6 0 00.723 0l3.155-2.396c.421-.319.971.185.689.633l-2.937 4.66a1.5 1.5 0 01-2.17.4l-2.336-1.75a.6.6 0 00-.723 0l-3.155 2.395c-.421.319-.971-.185-.689-.633z" />
    </Svg>
  );
}

export default SvgMessengerLine;
