import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgPencilRuler2Line(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path d="M7.05 14.121l-2.12 2.122 2.828 2.828L19.071 7.757 16.243 4.93 14.12 7.05l1.415 1.414L14.12 9.88l-1.414-1.415-1.414 1.415 1.414 1.414-1.414 1.414-1.414-1.414-1.415 1.414 1.415 1.414-1.415 1.415L7.05 14.12zm9.9-11.313l4.242 4.242a1 1 0 010 1.414L8.464 21.192a1 1 0 01-1.414 0L2.808 16.95a1 1 0 010-1.414L15.536 2.808a1 1 0 011.414 0zm-2.83 15.555l1.415-1.414 2.242 2.243h1.414v-1.414l-2.242-2.243 1.414-1.414L21 16.757V21h-4.242l-2.637-2.637zM5.636 9.878L2.807 7.05a1 1 0 010-1.415l2.829-2.828a1 1 0 011.414 0l2.83 2.828L8.464 7.05 6.343 4.928 4.929 6.343l2.121 2.12-1.414 1.415z" />
    </Svg>
  );
}

export default SvgPencilRuler2Line;
