import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgSettings6Fill(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path d="M17.5 2.474L23 12l-5.5 9.526h-11L1 12l5.5-9.526h11zM8.634 8.17l5 8.66 1.732-1-5-8.66-1.732 1z" />
    </Svg>
  );
}

export default SvgSettings6Fill;
