import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgLifebuoyLine(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm0 15a4.987 4.987 0 01-1.828-.345l-2.236 2.237A7.963 7.963 0 0012 20a7.963 7.963 0 004.064-1.108l-2.236-2.237A4.987 4.987 0 0112 17zm-8-5a7.96 7.96 0 001.108 4.064l2.237-2.236A4.987 4.987 0 017 12c0-.645.122-1.261.345-1.828L5.108 7.936A7.963 7.963 0 004 12zm14.892-4.064l-2.237 2.236c.223.567.345 1.183.345 1.828s-.122 1.261-.345 1.828l2.237 2.236A7.963 7.963 0 0020 12a7.963 7.963 0 00-1.108-4.064zM12 9a3 3 0 100 6 3 3 0 000-6zm0-5a7.963 7.963 0 00-4.064 1.108l2.236 2.237A4.987 4.987 0 0112 7c.645 0 1.261.122 1.828.345l2.236-2.237A7.963 7.963 0 0012 4z" />
    </Svg>
  );
}

export default SvgLifebuoyLine;
