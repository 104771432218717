import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgHandHeartLine(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path d="M5 9a1 1 0 011 1 6.97 6.97 0 014.33 1.5h2.17c1.332 0 2.53.579 3.353 1.499L19 13a5 5 0 014.516 2.851C21.151 18.972 17.322 21 13 21c-2.79 0-5.15-.603-7.06-1.658A.998.998 0 015 20H2a1 1 0 01-1-1v-9a1 1 0 011-1h3zm1.001 3L6 17.021l.045.033C7.84 18.314 10.178 19 13 19c3.004 0 5.799-1.156 7.835-3.13l.133-.133-.12-.1a2.994 2.994 0 00-1.643-.63L19 15l-2.112-.001c.073.322.112.657.112 1.001v1H8v-2l6.79-.001-.034-.078a2.501 2.501 0 00-2.092-1.416L12.5 13.5H9.57A4.985 4.985 0 006.002 12zM4 11H3v7h1v-7zm9.646-7.425L14 3.93l.354-.354a2.5 2.5 0 113.535 3.536L14 11l-3.89-3.89a2.5 2.5 0 113.536-3.535zm-2.12 1.415a.5.5 0 00-.06.637l.058.069L14 8.17l2.476-2.474a.5.5 0 00.058-.638l-.058-.07a.5.5 0 00-.638-.057l-.07.058-1.769 1.768-1.767-1.77-.068-.056a.5.5 0 00-.638.058z" />
    </Svg>
  );
}

export default SvgHandHeartLine;
