import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgBellLine(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path d="M14.121 9.879c4.296 4.295 6.829 8.728 5.657 9.9-.475.474-1.486.34-2.807-.273a9.008 9.008 0 01-10.59-.474l-.038.04-1.414-1.415.038-.04A9.006 9.006 0 014.495 7.03c-.614-1.322-.748-2.333-.273-2.808 1.128-1.128 5.277 1.177 9.417 5.182l.482.475zm-1.414 1.414C10.823 9.409 8.87 7.842 7.236 6.87l-.186.18a7.002 7.002 0 00-.657 9.142l1.846-1.846a2 2 0 111.416 1.415l-1.848 1.846a7.002 7.002 0 009.143-.657l.179-.188-.053-.089c-.976-1.615-2.52-3.53-4.369-5.38zm7.071-7.071a2 2 0 01-.164 2.976 9.015 9.015 0 01.662 8.345 21.168 21.168 0 00-1.386-2.306 6.99 6.99 0 00-1.94-6.187 6.992 6.992 0 00-6.187-1.94 21.092 21.092 0 00-2.306-1.386 9.016 9.016 0 018.347.663 2 2 0 012.974-.165z" />
    </Svg>
  );
}

export default SvgBellLine;
