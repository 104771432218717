import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgCommunityLine(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path d="M21 21H3a1 1 0 01-1-1v-7.513a1 1 0 01.343-.754L6 8.544V4a1 1 0 011-1h14a1 1 0 011 1v16a1 1 0 01-1 1zM9 19h3v-6.058L8 9.454l-4 3.488V19h3v-4h2v4zm5 0h6V5H8v2.127c.234 0 .469.082.657.247l5 4.359a1 1 0 01.343.754V19zm2-8h2v2h-2v-2zm0 4h2v2h-2v-2zm0-8h2v2h-2V7zm-4 0h2v2h-2V7z" />
    </Svg>
  );
}

export default SvgCommunityLine;
