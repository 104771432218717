import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgFileChartFill(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path d="M16 2l5 5v14.008a.993.993 0 01-.993.992H3.993A1 1 0 013 21.008V2.992C3 2.444 3.445 2 3.993 2H16zm-5 5v10h2V7h-2zm4 4v6h2v-6h-2zm-8 2v4h2v-4H7z" />
    </Svg>
  );
}

export default SvgFileChartFill;
