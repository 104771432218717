import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgGridFill(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path d="M14 10v4h-4v-4h4zm2 0h5v4h-5v-4zm-2 11h-4v-5h4v5zm2 0v-5h5v4a1 1 0 01-1 1h-4zM14 3v5h-4V3h4zm2 0h4a1 1 0 011 1v4h-5V3zm-8 7v4H3v-4h5zm0 11H4a1 1 0 01-1-1v-4h5v5zM8 3v5H3V4a1 1 0 011-1h4z" />
    </Svg>
  );
}

export default SvgGridFill;
