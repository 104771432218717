import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgRewindMiniFill(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path d="M11 17.035a.5.5 0 01-.788.409l-7.133-5.036a.5.5 0 010-.816l7.133-5.036a.5.5 0 01.788.409v10.07zm1.079-4.627a.5.5 0 010-.816l7.133-5.036a.5.5 0 01.788.409v10.07a.5.5 0 01-.788.409l-7.133-5.036z" />
    </Svg>
  );
}

export default SvgRewindMiniFill;
