import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgFile4Fill(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path d="M21 15h-7v7H3.998C3.447 22 3 21.545 3 21.008V2.992C3 2.444 3.445 2 3.993 2h16.014A1 1 0 0121 3.007V15zm0 2l-5 4.997V17h5z" />
    </Svg>
  );
}

export default SvgFile4Fill;
