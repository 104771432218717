import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgCharacterRecognitionFill(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path d="M21 3v18H3V3h18zm-8.001 3h-2L6.6 17h2.154l1.199-3h4.09l1.201 3h2.155l-4.4-11zm-1 2.885L13.244 12h-2.492l1.247-3.115z" />
    </Svg>
  );
}

export default SvgCharacterRecognitionFill;
