import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgCoinsFill(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path d="M14 2a8 8 0 013.292 15.293A8 8 0 116.706 6.707 8.003 8.003 0 0114 2zm-3 7H9v1a2.5 2.5 0 00-.164 4.995L9 15h2l.09.008a.5.5 0 010 .984L11 16H7v2h2v1h2v-1a2.5 2.5 0 00.164-4.995L11 13H9l-.09-.008a.5.5 0 010-.984L9 12h4v-2h-2V9zm3-5a5.985 5.985 0 00-4.484 2.013 8 8 0 018.47 8.471A6 6 0 0014 4z" />
    </Svg>
  );
}

export default SvgCoinsFill;
