import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgMoneyPoundBoxLine(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path d="M3 3h18a1 1 0 011 1v16a1 1 0 01-1 1H3a1 1 0 01-1-1V4a1 1 0 011-1zm1 2v14h16V5H4zm5 8H8v-2h1v-1a3.5 3.5 0 016.746-1.311l-1.986.496A1.499 1.499 0 0011 10v1h3v2h-3v2h5v2H8v-2h1v-2z" />
    </Svg>
  );
}

export default SvgMoneyPoundBoxLine;
