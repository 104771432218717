import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgClapperboardLine(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path d="M5.998 7l2.31-4h3.69l-2.31 4h-3.69zm6 0l2.31-4h3.69l-2.31 4h-3.69zm6 0l2.31-4h.7c.548 0 .992.445.992.993v16.014a1 1 0 01-.992.993H2.992A.993.993 0 012 20.007V3.993A1 1 0 012.992 3h3.006L4 6.46V19h16V7h-2.002z" />
    </Svg>
  );
}

export default SvgClapperboardLine;
