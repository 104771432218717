import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgShareForwardBoxLine(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path d="M9 3v2H4v14h16v-9h2v10a1 1 0 01-1 1H3a1 1 0 01-1-1V4a1 1 0 011-1h6zm9.95 2L16 2.05 17.414.636l5.34 5.34A.6.6 0 0122.33 7H14a2 2 0 00-2 2v6h-2V9a4 4 0 014-4h4.95z" />
    </Svg>
  );
}

export default SvgShareForwardBoxLine;
