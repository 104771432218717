import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgForbid2Fill(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm4.891-13.477a6.04 6.04 0 00-1.414-1.414l-8.368 8.368a6.04 6.04 0 001.414 1.414l8.368-8.368z" />
    </Svg>
  );
}

export default SvgForbid2Fill;
