import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgRedditFill(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm6.67-10a1.46 1.46 0 00-2.47-1 7.12 7.12 0 00-3.85-1.23L13 6.65l2.14.45a1 1 0 10.13-.61L12.82 6a.31.31 0 00-.37.24l-.74 3.47a7.14 7.14 0 00-3.9 1.23 1.46 1.46 0 10-1.61 2.39 2.87 2.87 0 000 .44c0 2.24 2.61 4.06 5.83 4.06s5.83-1.82 5.83-4.06a2.87 2.87 0 000-.44 1.46 1.46 0 00.81-1.33zm-10 1a1 1 0 112 0 1 1 0 01-2 0zm5.81 2.75a3.84 3.84 0 01-2.47.77 3.84 3.84 0 01-2.47-.77.27.27 0 01.38-.38A3.27 3.27 0 0012 16a3.28 3.28 0 002.09-.61.28.28 0 11.39.4v-.04zm-.18-1.71a1 1 0 111-1 1 1 0 01-1.01 1.04l.01-.04z" />
    </Svg>
  );
}

export default SvgRedditFill;
