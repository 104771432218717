import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgSlackFill(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path d="M6.527 14.514A1.973 1.973 0 014.56 16.48a1.973 1.973 0 01-1.967-1.967c0-1.083.884-1.968 1.967-1.968h1.968v1.968zm.992 0c0-1.083.884-1.968 1.967-1.968 1.083 0 1.968.885 1.968 1.968v4.927a1.973 1.973 0 01-1.968 1.967 1.973 1.973 0 01-1.967-1.967v-4.927zm1.967-7.987A1.973 1.973 0 017.52 4.56c0-1.083.884-1.967 1.967-1.967 1.083 0 1.968.884 1.968 1.967v1.968H9.486zm0 .992c1.083 0 1.968.884 1.968 1.967a1.973 1.973 0 01-1.968 1.968H4.56a1.973 1.973 0 01-1.967-1.968c0-1.083.884-1.967 1.967-1.967h4.927zm7.987 1.967c0-1.083.885-1.967 1.968-1.967s1.967.884 1.967 1.967a1.973 1.973 0 01-1.967 1.968h-1.968V9.486zm-.992 0a1.973 1.973 0 01-1.967 1.968 1.973 1.973 0 01-1.968-1.968V4.56c0-1.083.885-1.967 1.968-1.967s1.967.884 1.967 1.967v4.927zm-1.967 7.987c1.083 0 1.967.885 1.967 1.968a1.973 1.973 0 01-1.967 1.967 1.973 1.973 0 01-1.968-1.967v-1.968h1.968zm0-.992a1.973 1.973 0 01-1.968-1.967c0-1.083.885-1.968 1.968-1.968h4.927c1.083 0 1.967.885 1.967 1.968a1.973 1.973 0 01-1.967 1.967h-4.927z" />
    </Svg>
  );
}

export default SvgSlackFill;
