import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgRecordMailFill(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path d="M9.743 15h4.514a5.5 5.5 0 114.243 2h-13a5.5 5.5 0 114.243-2zM5.5 13a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm13 0a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
    </Svg>
  );
}

export default SvgRecordMailFill;
