import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgCoreosLine(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path d="M9.42 4.4a8 8 0 1010.202 9.91c-3.4 1.46-7.248 1.98-11.545 1.565-.711-4.126-.264-7.95 1.343-11.475zm2.448-.414a16.805 16.805 0 00-1.542 3.769 5.98 5.98 0 014.115 1.756 5.977 5.977 0 011.745 3.861c1.33-.341 2.589-.82 3.78-1.433a7.994 7.994 0 00-8.098-7.953zM4.895 19.057C.99 15.152.99 8.82 4.895 4.915c3.905-3.905 10.237-3.905 14.142 0 3.905 3.905 3.905 10.237 0 14.142-3.905 3.905-10.237 3.905-14.142 0zm5.02-9.293a17.885 17.885 0 00-.076 4.229 23.144 23.144 0 004.36-.22 3.988 3.988 0 00-1.172-2.848 3.99 3.99 0 00-3.112-1.161z" />
    </Svg>
  );
}

export default SvgCoreosLine;
