import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgDeleteBin3Line(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path d="M20 7v13a2 2 0 01-2 2H6a2 2 0 01-2-2V7H2V5h20v2h-2zM6 7v13h12V7H6zm5 2h2v2h-2V9zm0 3h2v2h-2v-2zm0 3h2v2h-2v-2zM7 2h10v2H7V2z" />
    </Svg>
  );
}

export default SvgDeleteBin3Line;
