import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgPrinterCloudLine(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path d="M17 2a1 1 0 011 1v4h3a1 1 0 011 1l.001 5.346a5.516 5.516 0 00-2-1.745L20 9H4v8h2v-1a1 1 0 011-1h5.207l-.071.283-.03.02A4.763 4.763 0 0010.567 17H8v3h2.06a4.73 4.73 0 00.817 2H7a1 1 0 01-1-1v-2H3a1 1 0 01-1-1V8a1 1 0 011-1h3V3a1 1 0 011-1h10zm.5 11a3.5 3.5 0 013.5 3.5l-.001.103a2.75 2.75 0 01-.581 5.392L20.25 22h-5.5l-.168-.005a2.75 2.75 0 01-.579-5.392L14 16.5a3.5 3.5 0 013.5-3.5zm0 2a1.5 1.5 0 00-1.473 1.215l-.02.14L16 16.5v1.62l-1.444.406a.75.75 0 00.08 1.466l.109.008h5.51a.75.75 0 00.19-1.474l-1.013-.283L19 18.12V16.5l-.007-.144A1.5 1.5 0 0017.5 15zM8 10v2H5v-2h3zm8-6H8v3h8V4z" />
    </Svg>
  );
}

export default SvgPrinterCloudLine;
