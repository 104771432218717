import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgUserReceived2Fill(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path d="M14 14.252V22H4a8 8 0 0110-7.748zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm8 4h3v2h-3v3.5L15 18l5-4.5V17z" />
    </Svg>
  );
}

export default SvgUserReceived2Fill;
