import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgApps2Fill(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path d="M7 11.5a4.5 4.5 0 110-9 4.5 4.5 0 010 9zm0 10a4.5 4.5 0 110-9 4.5 4.5 0 010 9zm10-10a4.5 4.5 0 110-9 4.5 4.5 0 010 9zm0 10a4.5 4.5 0 110-9 4.5 4.5 0 010 9z" />
    </Svg>
  );
}

export default SvgApps2Fill;
