import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgTShirt2Line(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path d="M9 3a3 3 0 006 0h6a1 1 0 011 1v7a1 1 0 01-1 1h-2.001L19 20a1 1 0 01-1 1H6a1 1 0 01-1-1l-.001-8.001L3 12a1 1 0 01-1-1V4a1 1 0 011-1h6zm11 1.999h-3.417l-.017.041a5.002 5.002 0 01-4.35 2.955L12 8a5.001 5.001 0 01-4.566-2.96L7.416 5H4v5l2.999-.001V19H17l-.001-9L20 9.999v-5z" />
    </Svg>
  );
}

export default SvgTShirt2Line;
