import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgStore3Fill(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path d="M21 13v7a1 1 0 01-1 1H4a1 1 0 01-1-1v-7H2v-2l1-5h18l1 5v2h-1zM5 13v6h14v-6H5zm1 1h8v3H6v-3zM3 3h18v2H3V3z" />
    </Svg>
  );
}

export default SvgStore3Fill;
