import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgMessage3Fill(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path d="M2 8.994A5.99 5.99 0 018 3h8c3.313 0 6 2.695 6 5.994V21H8c-3.313 0-6-2.695-6-5.994V8.994zM14 11v2h2v-2h-2zm-6 0v2h2v-2H8z" />
    </Svg>
  );
}

export default SvgMessage3Fill;
