import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgUser5Line(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path d="M7.39 16.539a8 8 0 119.221 0l2.083 4.76a.5.5 0 01-.459.701H5.765a.5.5 0 01-.459-.7l2.083-4.761zm6.735-.693l1.332-.941a6 6 0 10-6.913 0l1.331.941L8.058 20h7.884l-1.817-4.154zM8.119 10.97l1.94-.485a2 2 0 003.882 0l1.94.485a4.002 4.002 0 01-7.762 0z" />
    </Svg>
  );
}

export default SvgUser5Line;
