import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgPushpin2Fill(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path d="M18 3v2h-1v6l2 3v2h-6v7h-2v-7H5v-2l2-3V5H6V3z" />
    </Svg>
  );
}

export default SvgPushpin2Fill;
