import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgUbuntuLine(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path d="M8.667 19.273l1.006-1.742a6.001 6.001 0 008.282-4.781h2.012A7.97 7.97 0 0118.928 16a8 8 0 01-1.452 1.835 2.493 2.493 0 00-1.976.227 2.493 2.493 0 00-1.184 1.596 7.979 7.979 0 01-5.65-.385zm-1.3-.75a7.979 7.979 0 01-3.156-4.7A2.494 2.494 0 005 12c0-.72-.304-1.369-.791-1.825A8 8 0 015.072 8a7.97 7.97 0 012.295-2.524l1.006 1.742a6.001 6.001 0 000 9.563l-1.005 1.742zm1.3-13.796a8.007 8.007 0 015.648-.387 2.497 2.497 0 003.161 1.825 8.007 8.007 0 012.49 5.085h-2.013A5.99 5.99 0 0015 6.804a5.99 5.99 0 00-5.327-.335L8.667 4.727zM16 5.072a1.5 1.5 0 111.5-2.598A1.5 1.5 0 0116 5.072zM4 12a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm12 6.928a1.5 1.5 0 111.5 2.598 1.5 1.5 0 01-1.5-2.598z" />
    </Svg>
  );
}

export default SvgUbuntuLine;
