import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgZoomInFill(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0111 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 01-1.969 5.617zM10 10H7v2h3v3h2v-3h3v-2h-3V7h-2v3z" />
    </Svg>
  );
}

export default SvgZoomInFill;
