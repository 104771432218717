import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgCake3Line(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path d="M15.5 2a3.5 3.5 0 013.437 4.163l-.015.066a4.502 4.502 0 01.303 8.428l-1.086 6.507a1 1 0 01-.986.836H6.847a1 1 0 01-.986-.836l-1.029-6.17a3 3 0 01-.829-5.824L4 9a6 6 0 018.574-5.421A3.496 3.496 0 0115.5 2zM9 15H6.86l.834 5H9v-5zm4 0h-2v5h2v-5zm4.139 0H15v5h1.305l.834-5zM10 5C7.858 5 6.109 6.684 6.005 8.767L6 8.964l.003.17a2 2 0 01-1.186 1.863l-.15.059A1.001 1.001 0 005 13h12.5a2.5 2.5 0 10-.956-4.81l-.175.081a2 2 0 01-2.663-.804l-.07-.137A4 4 0 0010 5zm5.5-1a1.5 1.5 0 00-1.287.729 6.006 6.006 0 011.24 1.764 4.45 4.45 0 011.446-.453A1.5 1.5 0 0015.5 4z" />
    </Svg>
  );
}

export default SvgCake3Line;
