import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgFolder3Fill(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path d="M22 8v12a1 1 0 01-1 1H3a1 1 0 01-1-1V7h19a1 1 0 011 1zm-9.586-3H2V4a1 1 0 011-1h7.414l2 2z" />
    </Svg>
  );
}

export default SvgFolder3Fill;
