import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgShoppingBasket2Line(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path d="M15.366 3.438L18.577 9H22v2h-1.167l-.757 9.083a1 1 0 01-.996.917H4.92a1 1 0 01-.996-.917L3.166 11H2V9h3.422l3.212-5.562 1.732 1L7.732 9h8.535l-2.633-4.562 1.732-1zM18.826 11H5.173l.667 8h12.319l.667-8zM13 13v4h-2v-4h2zm-4 0v4H7v-4h2zm8 0v4h-2v-4h2z" />
    </Svg>
  );
}

export default SvgShoppingBasket2Line;
