import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgExchangeFundsLine(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path d="M19.375 15.103A8.001 8.001 0 008.03 5.053l-.992-1.737A9.996 9.996 0 0117 3.34c4.49 2.592 6.21 8.142 4.117 12.77l1.342.774-4.165 2.214-.165-4.714 1.246.719zM4.625 8.897a8.001 8.001 0 0011.345 10.05l.992 1.737A9.996 9.996 0 017 20.66C2.51 18.068.79 12.518 2.883 7.89L1.54 7.117l4.165-2.214.165 4.714-1.246-.719zm8.79 5.931L10.584 12l-2.828 2.828-1.414-1.414 4.243-4.242L13.414 12l2.829-2.828 1.414 1.414-4.243 4.242z" />
    </Svg>
  );
}

export default SvgExchangeFundsLine;
