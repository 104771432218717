import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgSingleQuotesR(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path d="M14.417 6.679C15.447 7.773 16 9 16 10.989c0 3.5-2.457 6.637-6.03 8.188l-.893-1.378c3.335-1.804 3.987-4.145 4.247-5.621-.537.278-1.24.375-1.929.311C9.591 12.322 8.17 10.841 8.17 9a3.5 3.5 0 013.5-3.5c1.073 0 2.099.49 2.748 1.179z" />
    </Svg>
  );
}

export default SvgSingleQuotesR;
