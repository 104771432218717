import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgSwitchFill(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path d="M13.619 21c-.085 0-.141-.057-.127-.127V3.127c0-.056.042-.113.113-.113h2.785A4.61 4.61 0 0121 7.624v8.766A4.61 4.61 0 0116.39 21h-2.77zm3.422-9.926c-1.004 0-1.824.82-1.824 1.824s.82 1.824 1.824 1.824 1.824-.82 1.824-1.824-.82-1.824-1.824-1.824zM5.8 8.4a1.7 1.7 0 011.696-1.696A1.7 1.7 0 019.193 8.4c0 .934-.763 1.697-1.697 1.697A1.702 1.702 0 015.8 8.401zM11.54 3c.085 0 .142.057.128.127V20.86c0 .07-.057.127-.128.127H7.61A4.61 4.61 0 013 16.376V7.61A4.61 4.61 0 017.61 3h3.93zm-1.315 16.544V4.442H7.61c-.849 0-1.64.34-2.235.933a3.088 3.088 0 00-.933 2.235v8.766c0 .849.34 1.64.933 2.234a3.088 3.088 0 002.235.934h2.615z" />
    </Svg>
  );
}

export default SvgSwitchFill;
