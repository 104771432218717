import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgSettings3Fill(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path d="M9.954 2.21a9.99 9.99 0 014.091-.002A3.993 3.993 0 0016 5.07a3.993 3.993 0 003.457.261A9.99 9.99 0 0121.5 8.876 3.993 3.993 0 0020 12a3.99 3.99 0 001.502 3.124 10.043 10.043 0 01-2.046 3.543 3.993 3.993 0 00-3.456.261 3.993 3.993 0 00-1.954 2.86 9.99 9.99 0 01-4.091.004A3.993 3.993 0 008 18.927a3.993 3.993 0 00-3.457-.26A9.99 9.99 0 012.5 15.121 3.993 3.993 0 004 11.999a3.993 3.993 0 00-1.502-3.124 10.043 10.043 0 012.046-3.543A3.993 3.993 0 008 5.071a3.993 3.993 0 001.954-2.86zM12 15a3 3 0 100-6 3 3 0 000 6z" />
    </Svg>
  );
}

export default SvgSettings3Fill;
