import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgOilLine(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path d="M9.07 7L6 11.606V20h12V7H9.07zM8 5h11a1 1 0 011 1v15a1 1 0 01-1 1H5a1 1 0 01-1-1V11l4-6zm5-4h5a1 1 0 011 1v2h-7V2a1 1 0 011-1zM8 12h2v6H8v-6z" />
    </Svg>
  );
}

export default SvgOilLine;
