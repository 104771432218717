import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgVirusFill(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path d="M13.717 1.947l3.734 1.434-.717 1.867-.934-.359-.746 1.945a6.042 6.042 0 011.945 1.846l1.903-.847-.407-.914 1.827-.813 1.627 3.654-1.827.813-.407-.913-1.902.847a6.023 6.023 0 01.07 2.68l1.944.746.358-.933 1.868.717-1.434 3.734-1.867-.717.358-.933-1.944-.747a6.042 6.042 0 01-1.846 1.945l.847 1.903.914-.407.813 1.827-3.654 1.627-.813-1.827.913-.407-.847-1.902a6.023 6.023 0 01-2.681.069l-.746 1.945.934.358-.717 1.868-3.734-1.434.717-1.867.932.358.748-1.944A6.055 6.055 0 017 15.32l-1.903.847.407.914-1.827.813L2.05 14.24l1.827-.813.406.914 1.903-.848a6.07 6.07 0 01-.069-2.68l-1.945-.746-.357.933-1.868-.717L3.381 6.55l1.867.717-.359.933 1.945.747A6.048 6.048 0 018.68 7l-.847-1.903-.914.407-.813-1.827L9.76 2.051l.813 1.827-.913.407.847 1.902a6.023 6.023 0 012.68-.07l.745-1.945L13 3.815l.717-1.868zm-3.583 11.285a1 1 0 101.733 1 1 1 0 00-1.733-1zM14 11a1 1 0 100 2 1 1 0 000-2zm-3.5-1.598a1 1 0 101.001 1.732A1 1 0 0010.5 9.402z" />
    </Svg>
  );
}

export default SvgVirusFill;
