import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgLineLine(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path d="M22 10.69c0 1.787-.687 3.4-2.123 4.974-2.1 2.414-6.788 5.363-7.864 5.812-1.074.451-.911-.287-.874-.537l.137-.85c.034-.262.068-.65-.032-.9-.112-.277-.556-.424-.881-.492C5.558 18.059 2 14.7 2 10.69c0-4.475 4.487-8.118 10-8.118 5.512 0 10 3.643 10 8.118zm-3.6 3.625c1.113-1.22 1.6-2.361 1.6-3.625 0-3.268-3.51-6.118-8-6.118s-8 2.85-8 6.118c0 2.905 2.728 5.507 6.626 6.024l.147.026c1.078.226 1.884.614 2.329 1.708l.036.096c1.806-1.176 4.174-2.98 5.261-4.229zm-.262-4a.526.526 0 010 1.05h-1.463v.938h1.462a.525.525 0 110 1.049H16.15a.526.526 0 01-.522-.524V8.852c0-.287.235-.525.525-.525h1.988a.525.525 0 01-.003 1.05h-1.462v.938h1.462zm-3.213 2.513a.524.524 0 01-.526.522.515.515 0 01-.425-.208l-2.036-2.764v2.45a.525.525 0 01-1.047 0V8.852a.522.522 0 01.52-.523c.162 0 .312.086.412.211l2.052 2.775V8.852c0-.287.235-.525.525-.525.287 0 .525.238.525.525v3.976zm-4.784 0a.527.527 0 01-.526.524.526.526 0 01-.523-.524V8.852c0-.287.236-.525.525-.525.289 0 .524.238.524.525v3.976zm-2.055.524H6.097a.528.528 0 01-.525-.524V8.852a.527.527 0 011.05 0v3.45h1.464a.525.525 0 010 1.05z" />
    </Svg>
  );
}

export default SvgLineLine;
