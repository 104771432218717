import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgVipFill(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path d="M3 3h18a1 1 0 011 1v16a1 1 0 01-1 1H3a1 1 0 01-1-1V4a1 1 0 011-1zm8 5.5v7h2v-7h-2zm-.285 0H8.601l-1.497 4.113L5.607 8.5H3.493l2.611 6.964h2L10.715 8.5zm5.285 5h1.5a2.5 2.5 0 100-5H14v7h2v-2zm0-2v-1h1.5a.5.5 0 110 1H16z" />
    </Svg>
  );
}

export default SvgVipFill;
