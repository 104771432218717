import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgHandSanitizerFill(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path d="M17 2v2l-4-.001V6h3v2c2.21 0 4 1.79 4 4v8a2 2 0 01-2 2H6a2 2 0 01-2-2v-8c0-2.21 1.79-4 4-4V6h3V3.999L7.5 4c-.63 0-1.37.49-2.2 1.6L3.7 4.4C4.87 2.84 6.13 2 7.5 2H17zm-4 10h-2v2H9v2h1.999L11 18h2l-.001-2H15v-2h-2v-2z" />
    </Svg>
  );
}

export default SvgHandSanitizerFill;
