import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgSettings4Fill(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path d="M5.334 4.545a9.99 9.99 0 013.542-2.048A3.993 3.993 0 0012 3.999a3.993 3.993 0 003.124-1.502 9.99 9.99 0 013.542 2.048 3.993 3.993 0 00.262 3.454 3.993 3.993 0 002.863 1.955 10.043 10.043 0 010 4.09c-1.16.178-2.23.86-2.863 1.955a3.993 3.993 0 00-.262 3.455 9.99 9.99 0 01-3.542 2.047A3.993 3.993 0 0012 20a3.993 3.993 0 00-3.124 1.502 9.99 9.99 0 01-3.542-2.047A3.993 3.993 0 005.072 16a3.993 3.993 0 00-2.863-1.954 10.043 10.043 0 010-4.091A3.993 3.993 0 005.072 8a3.993 3.993 0 00.262-3.454zM13.5 14.597a3 3 0 10-3-5.196 3 3 0 003 5.196z" />
    </Svg>
  );
}

export default SvgSettings4Fill;
